/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';
import { FilterForfaitProvider } from 'contexts/FilterForfaitContext';
import { useEffect, useState } from 'react';
import ColumnsTable from 'views/admin/forfaits/components/ColumnsTable'; 
import { useForfait } from '../../../hooks/useForfait';

export default function Settings() {

  const [data, setData] = useState(null);
  const { fetch } = useForfait();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch();
        setData(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb='20px' columns={1} spacing={{ base: '20px', xl: '20px' }}>
        <FilterForfaitProvider>
          {data && <ColumnsTable tableData={data} />}
        </FilterForfaitProvider>
      </SimpleGrid>
    </Box>
  );
}
