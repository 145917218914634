import { Controller, useForm } from "react-hook-form";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  Timestamp,
} from "firebase/firestore";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Spacer,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  RadioGroup,
  Stack,
  Radio,
  Checkbox,
} from "@chakra-ui/react";
import { db } from "firebaseConfig";
import { useForfait } from "../../hooks/useForfait";
import { useClient } from "../../hooks/useClient";
import { ChangeEvent, useEffect, useState } from "react";
import { dateToSeconds } from "utils";
import moment from "moment";
import { useForfaitFilter } from "contexts/FilterForfaitContext";

interface ForfaitFormProps {
  onCloseModal: () => void;
  newData: (data: ForfaitType[]) => void;
  updateData: ForfaitType[];
}

export default function ForfaitForm(props: ForfaitFormProps) {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ForfaitType>();

  const [clients, setClients] = useState<ClientType[] | null>(null);
  const { fetch, getForfaitByStatus } = useForfait();
  const { getClients } = useClient();
  const [selectedClient, setSelectedClient] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getClients();
        setClients(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    if (props.updateData?.[0]?.idclient) {
      setValue("idclient", props.updateData[0].idclient);
      setSelectedClient(props.updateData[0].idclient);
    }
  }, []);

  const handleSelectChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedClient(event.target.value);
    setValue("idclient", event.target.value);
  };

  const { filterStatus } = useForfaitFilter();

  async function onSubmit(values: ForfaitType) {
    try {
      const dateDebut =
        typeof values.date_debut === "string"
          ? Timestamp.fromDate(new Date(values.date_debut))
          : values.date_debut;
      const statut = Boolean(values.statut);
      const newValues = { ...values, date_debut: dateDebut, statut: statut };
      if (props.updateData === null) {
        await addDoc(collection(db, "forfait"), newValues);
      } else {
        const updateForfait = doc(db, "forfait", props.updateData[0].id);
        await updateDoc(updateForfait, newValues);
      }
      props.onCloseModal();
      let newData: ForfaitType[];
      
      if(filterStatus === "true"){
        newData = await getForfaitByStatus(true);
      }else if(filterStatus === "false"){
        newData = await getForfaitByStatus(false);
      }else{
        newData = await fetch();
      }
      props.newData(newData);
    } catch (error) {
      console.error("Error adding data to Firebase:", error);
    }
  }
  const getUpdateDataValue = (field: keyof ForfaitType) => {
    return props.updateData !== null ? props.updateData[0][field] : "";
  };

  const formatDateIntervention = () => {
    let dateIntervention;
    if (props.updateData) {
      if (props.updateData[0].date_debut instanceof Date) {
        dateIntervention = moment(props.updateData[0].date_debut);
      } else {
        dateIntervention = moment(
          Number(props.updateData[0].date_debut.seconds) * 1000 +
            Number(props.updateData[0].date_debut.nanoseconds) / 1000000
        );
      }
      return dateIntervention.format("YYYY-MM-DDTHH:mm");
    } else {
      return "";
    }
  };

  const renderInput = (
    field: keyof ForfaitType,
    placeholder: string,
    minLength: number,
    typeInput: string
  ) => {
    const defaultValue = getUpdateDataValue(field);
    const maxNumber = 30;
    const defaultValueForInput =
      typeInput === "datetime-local" ? formatDateIntervention() : defaultValue;

    if (typeInput === "select") {
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <FormLabel htmlFor={field}>{placeholder}</FormLabel>
          <Controller
            name="idclient"
            control={control}
            defaultValue={props.updateData?.[0]?.idclient}
            render={({ field: { ref, value, onChange, ...field } }) => {
              return (
                <Select
                  placeholder="Client"
                  {...register("idclient", {
                    required: "Champ obligatoire",
                    // ... autres règles de validation
                  })}
                  {...field}
                  ref={ref}
                  value={selectedClient}
                  onChange={handleSelectChange}
                >
                  {/* Générez les options à partir des données des clients */}
                  {clients &&
                    clients.map((client) => (
                      <option key={client.id} value={client.id}>
                        {client.raison_sociale}
                      </option>
                    ))}
                </Select>
              );
            }}
          />
          <FormErrorMessage>
            {errors[field] && errors[field].message}
          </FormErrorMessage>
        </FormControl>
      );
    } else if (typeInput === "number") {
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <FormLabel htmlFor={field}>{placeholder}</FormLabel>
          <NumberInput
            defaultValue={Number(defaultValue)}
            max={maxNumber}
            clampValueOnBlur={false}
          >
            <NumberInputField
              id={field}
              {...register(field, {
                required: "Champ obligatoire",
                valueAsNumber: true,
                // ... autres règles de validation
              })}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>
            {errors[field] && errors[field].message}
          </FormErrorMessage>
        </FormControl>
      );
    } else if (typeInput === "boolean") {
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <Checkbox
            name="statut"
            {...register("statut")}
            defaultChecked={defaultValue as boolean}
          >
            Forfait actif
          </Checkbox>
        </FormControl>
      );
    } else {
      // Utilisez le bloc else pour gérer d'autres types d'entrées
      return (
        <FormControl isInvalid={!!errors[field]} mb={4} key={field}>
          <FormLabel htmlFor={field}>{placeholder}</FormLabel>
          <Input
            id={field}
            placeholder={placeholder}
            {...register(field, {
              required: minLength > 0 ? "Champ obligatoire" : false,
              minLength: {
                value: minLength,
                message: `La longueur minimum est de ${minLength} caractères`,
              },
            })}
            defaultValue={defaultValueForInput as string}
            type={typeInput}
          />
          <FormErrorMessage>
            {errors[field] && errors[field].message}
          </FormErrorMessage>
        </FormControl>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderInput("date_debut", "Date de début", 4, "datetime-local")}
      {renderInput("idclient", "Id Client", 4, "select")}
      {renderInput("prix", "Prix", 2, "number")}
      {renderInput("remarque_tech", "Remarque", 0, "text")}
      {renderInput("statut", "Statut", 1, "boolean")}
      {renderInput("temps", "Temps", 1, "number")}
      {renderInput("type", "Type", 1, "number")}

      <Button
        mt={4}
        colorScheme="teal"
        isLoading={isSubmitting}
        type="submit"
        mb={4}
      >
        {props.updateData !== null
          ? `Modifier le forfait`
          : `Ajouter le forfait`}
      </Button>
    </form>
  );
}
