import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FilterProviderProps {
  children: ReactNode;
}

const FilterForfaitContext = createContext<{ filterStatus: string; setFilterStatus: (status: string) => void } | undefined>(undefined);

export const FilterForfaitProvider = ({ children }: FilterProviderProps) => {
  const [filterStatus, setFilterStatus] = useState<string>("");

  return (
    <FilterForfaitContext.Provider value={{ filterStatus, setFilterStatus }}>
      {children}
    </FilterForfaitContext.Provider>
  );
};

// Créer un hook personnalisé pour utiliser le contexte
export const useForfaitFilter = () => {
  const context = useContext(FilterForfaitContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};
