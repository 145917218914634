/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Avatar, Box, Flex, FormLabel, Icon, Select, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { useForfait } from 'hooks/useForfait';
import { useIntervention } from 'hooks/useIntervention';
import { useEffect, useState } from 'react';
import { MdCheckBox, MdEuro, MdFileCopy } from 'react-icons/md';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex';

export default function UserReports() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	const [dataActifs, setDataActifs] = useState<ForfaitType[]>([]);
	const [dataInactifs, setDataInactifs] = useState<ForfaitType[]>([]);
	const [dataIntervention, setDataIntervention] = useState<InterventionType[]>([]);
	const { getForfaitByStatus } = useForfait();
	const { fetch, getInterventionsByIdForfait } = useIntervention();
	const [forfaitsWithLowTime, setForfaitsWithLowTime] = useState<number>(0);
  const [totalPrixForfaitsLowTime, setTotalPrixForfaitsLowTime] = useState<number>(0);

  useEffect(() => {
		const fetchForfaits = async () => {
			try {
				// Lancer les deux requêtes en parallèle avec Promise.all
				const [forfaitActifs, forfaitInactifs, interventions] = await Promise.all([
					getForfaitByStatus(true),
					getForfaitByStatus(false),
					fetch(),
				]);
	
				// Mettre à jour les états avec les résultats des deux requêtes
				setDataActifs(forfaitActifs);
				setDataInactifs(forfaitInactifs);
				setDataIntervention(interventions);
			} catch (error) {
				console.error('Erreur lors de la récupération des forfaits :', error);
			}
		};
		const fetchForfaitsAndInterventions = async () => {
      try {
        const [forfaitActifs] = await Promise.all([
          getForfaitByStatus(true),
        ]);
        setDataActifs(forfaitActifs || []);
        let lowTimeCount = 0;
        let totalPrix = 0; 
        await Promise.all(
          forfaitActifs.map(async (forfait) => {
            const fetchedInterventions = await getInterventionsByIdForfait(forfait.id);
            
            const sommeTempsPasse = fetchedInterventions.reduce(
              (acc, intervention) => acc + (intervention.temps_passe || 0),
              0
            );
            const time = forfait.temps - sommeTempsPasse;
            if (time <= 1) {			
              lowTimeCount += 1;
              totalPrix += forfait.prix || 0;
            }
						setForfaitsWithLowTime(lowTimeCount);
        		setTotalPrixForfaitsLowTime(totalPrix);
          })
        );
      } catch (error) {
        console.error('Erreur lors de la récupération des forfaits et interventions:', error);
      }
    };

    fetchForfaitsAndInterventions();
		fetchForfaits();
	}, []);
	const totalPrixActifs = dataActifs.reduce((acc, forfait) => acc + (forfait.prix || 0), 0);
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdEuro} color={brandColor} />}
						/>
					}
					name='€ to come'
					value={`${totalPrixForfaitsLowTime}€`}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdEuro} color={brandColor} />}
						/>
					}
					name='€ actifs'
					value={`${totalPrixActifs}€`}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg='teal'
							icon={<Icon w='28px' h='28px' as={MdCheckBox} color='white' />}
						/>
					}
					name='Forfaits actifs'
					value={dataActifs.length}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg='orange'
							icon={<Icon w='28px' h='28px' as={MdCheckBox} color='white' />}
						/>
					}
					name='To renew'
					value={forfaitsWithLowTime}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg='red'
							icon={<Icon w='28px' h='28px' as={MdCheckBox} color='white' />}
						/>
					}
					name='Forfaits inactifs'
					value={dataInactifs.length}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />}
						/>
					}
					name='Total Interventions'
					value={dataIntervention.length}
				/>
			</SimpleGrid>

			<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
				<TotalSpent />
				<WeeklyRevenue />
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<DailyTraffic />
					<PieCard />
				</SimpleGrid>
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<ComplexTable tableData={tableDataComplex} />
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<Tasks />
					<MiniCalendar h='100%' minW='100%' selectRange={false} />
				</SimpleGrid>
			</SimpleGrid>
		</Box>
	);
}
